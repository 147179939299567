import React from "react"
import Layout from '../containers/layout/layout'
import SEO from "../components/seo"
import Header from '../containers/layout/header/header-one'
import Footer from '../containers/layout/footer/footer-one'
import PageHeader from '../components/pageheader'
import {Container} from '../components/ui/wrapper/container';
import {Row} from '../components/ui/wrapper/row';
import {Col} from '../components/ui/wrapper/col';
import SectionTitle from '../components/ui/section-title';

const PrivacyPolicy = ({pageContext, location}) => (
  <Layout location={location}>
    <SEO title="Privacy policy" />
    <Header/>
    <PageHeader
      pageContext={pageContext}
      location={location}
      title="Privacy policy"
    />
    <main className="site-wrapper-reveal">
      <Container>
        <Row mt="40px" mb="350px">
          <Col lg={12}>
            <SectionTitle
              mb="40px"
              title="Privacy policy"
              subtitle="This is"
            />
          </Col>
          <Col lg={8} className="pl-5 pr-5">
             <span>Last updated: December 07,2020</span>

          <p>Welcome to TRIPLOAF’s privacy notice</p>

          <p>TRIPLOAF respects your privacy and is committed to protecting your personal data. This privacy notice describes how we look after your personal data when you visit and use our website. TRIPLOAF  ("us", "we", or "our") operates https://travelsmarter-new-178ujhaj8.vercel.app (the "Site"). This page informs you of our policies regarding the collection, use and disclosure of Personal Information we receive from users of the Site.</p>

          <p>We use your Personal Information only for providing and improving the Site. By using the Site, you agree to the collection and use of information in accordance with this policy.</p>

          <p><h5>Information Manager</h5></p>

          <p>MRGV is the controller and responsible for your personal data (Referred to as “TRIPLOAF”, “we”, “us” or “our” in this privacy notice). </p>

          <p>MRGV is made up of different entities. This privacy notice is issued on behalf of MRGV so when we mention “TRIPLOAF”, “we”, “us” or “our” in this privacy notice, we are referring to the relevant company in MRGV responsible for processing your data. We will let you know which entity will be the information manager for your data when you purchase a product or service with us. </p>

          <p>We have appointed a data privacy manager who is responsible for overseeing questions in relations to this privacy notice. If you have questions about this privacy notice, including requests to exercise your rights, please contact the data manager using the details set out below. </p>

          <p>Contact details:</p>

          <p>Full Name of Entity: MRGV </p>

          <p>Email address: <b>hello@mrgv.co</b> </p>

          <p>Postal address:</p>


          <p><h5>Changes to the privacy notice and your duty to inform us of changes</h5></p>

          <p>This privacy policy last update date is shown above and historic versions can be obtained by contacting us using the contact details in the paragraph above.</p>



          <p><h5>What data do we collect?</h5> </p>

          <p>Personal data, or personal information, means any information about an individual from which that person can be identified. It does not include data where the identity has been removed (i.e. anonymous data).</p>

          <p>We may collect, use, store and transfer different kinds of personal data about you which we have grouped together below.</p>

          <p>Identity Data includes first name, maiden name, last name, username or similar identifier, marital status, title, age and gender, your photograph (where you sign-up to an account with us and choose to include your picture on your account).</p>

          <p>Contact Data such as email address or telephone numbers </p>

          <p>Technical Data such as internet protocol (IP) address, your login data, browser type (for website use), time zone setting and location and other technology related information about the devices you use to access the Site </p>

          <p>Usage Data includes information about how you use the Site</p>

          <p>Marketing includes your preferences in receiving marketing from us </p>

          <p><h5>How do we collect your data?</h5></p>

          <p>Data is collected through different methods of site usage such as direct interactions through forms on the Site, Accounts, Subscriptions, Feedback, Contact Us Ticket, Logs & similar technologies. </p>


          <p><h5>What do we do with your data?</h5> </p>

          <p><h6>The following is a brief outline of data use by Triploaf: </h6></p>
          <ul>
            <li>Account Registration: registry of client accounts and purchase of products </li>
            <li>Client Relationship Management: notifications, changes to policies, quality assurance and queries regarding Site interactions</li>
            <li>Website Administration and Maintenance: management of business continuity and use of the site </li>
            <li>Advertisement and data analytics: adequate definition of user and customer based for the Site’s own use</li>
            <li>Service delivery: to allow users to book travels, services, experiences and other Site offers </li>
          </ul>


          <br />

          <p><h5>Marketing emails</h5></p>

          <p>You can ask us to stop sending you marketing messages at any time by following the opt-out links on any marketing message sent to you (it may take up to 3 days to stop receiving marketing messages), or by contacting us at any time <b>hello@mrgv.co</b></p>


          <p><h5>Cookies</h5></p>
          <p>You can set your browser to refuse all or some browser cookies, or to alert you when websites set or access cookies. If you disable or refuse cookies, please note that some parts of the Website may become inaccessible or not function properly</p>


          <p><h5>Do we disclose your personal data to third parties?</h5> </p>
          <p>We may have to share your personal data with third parties to whom we may choose to sell, transfer, or merge parts of our business or our assets. Alternatively, we may seek to acquire other businesses or merge with them. If a change happens to our business, then the new owners may use your personal data in the same way as set out in this privacy notice.</p>

          <p>We require all third parties to respect the security of your personal data and to treat it in accordance with the law. We do not allow our third-party service providers to use your personal data for their own purposes and only permit them to process your personal data for specified purposes and in accordance with our instructions.</p>


          <p><h5>Do we have systems in place to secure your data?</h5> </p>

          <p>We have put in place appropriate security measures to prevent your personal data from being accidentally lost, used or accessed in an unauthorised way, altered or disclosed. In addition, we limit access to your personal data to those employees, agents, contractors and other third parties who have a business need to know. They will only process your personal data on our instructions and they are subject to a duty of confidentiality.</p>

          <p><h5>How long do we store your personal data?</h5></p>
          <p>As long as you have an active user and for as long as necessary to fulfil the purposes we collected it for, including for the purposes of satisfying any legal, accounting, or reporting requirements.</p>

          <p><h5>What are some of your user legal rights as a user of our Site?</h5></p>

          <p>Under certain circumstances, you have rights under data protection laws in relation to your personal data. Some of these are:</p>

          <p>Request access to your personal data</p>
          <p>Request correction of your personal data</p>
          <p>Request erasure of your personal data</p>
          <p>Object to processing of your personal data</p>
          <p>Request restriction of processing your personal data</p>
          <p>Request transfer of your personal data</p>
          <p>Right to withdraw consent</p>

          <p>If you wish to exercise any of the rights set out above, please Contact us by emailing <b>hello@mrgv.co</b> </p>
          </Col>
        </Row>        
      </Container>
    </main>
    <Footer/>
  </Layout>
)

export default PrivacyPolicy;
